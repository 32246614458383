import React from 'react'

const HomeSplash = () => {
    return (
        <>
            <img src={'images/logo.png'} style={{width: '50%', margin: '2vw 0 1vw 0'}}alt='Fore the Record' />
            <h3>A WEBSITE FOR TRACKING GOLF WITH YOUR FRIENDS SCORES</h3>
        </>
    )
}

export default HomeSplash